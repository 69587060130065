<template>
    <div class="aqDiv">
        <div style="display: flex;flex-wrap: wrap; justify-content: space-between;margin-top:60px">
            <el-col :span=5 class="aqImgDiv" v-for="(item,i) in list" :key="i">
                <img :src="item"/>
            </el-col>
           
        </div>
        
    </div>
</template>
<script>
export default {
    name:"aboutQualify",
    data(){
        return{
            list:[]
        }
    },
    methods:{
        init(){
            let list=[]
            for(let i=1;i<=12;i++){
                let imgUrl=require("../../static/zizhi/zizhi"+i+".jpg")
                list.push(imgUrl)
            }
            this.list=list
        }

    },
    created(){
        this.init()
    }
}
</script>
<style>
.aqDiv{
    padding-bottom:70px;
}
.aqDiv .aqImgDiv{
    margin-top:20px;
    padding:10px;
    background: #fff;
    box-shadow:0px 2px 10px 0px #e1e1e1;
}

.aqDiv .aqImgDiv img{
    width:100%
}

</style>